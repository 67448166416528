import React from 'react';

import AuthBlock from "../../components/LoginPage/AuthBlock/AuthBlock";

function LoginPage() {
    return (
        <AuthBlock/>
    );
}

export default LoginPage;