// auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_LOADING_START = "LOGIN_LOADING_START"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_LOADING_START = "UPDATE_PASSWORD_LOADING_START"
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"

export const LOGOUT_USER  = "LOGOUT_USER"


// cards
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS"
export const GET_CARDS_LOADING_START = "GET_CARDS_LOADING_START"
export const GET_CARDS_ERROR = "GET_CARDS_ERROR"

export const ADD_CARDS_SUCCESS = "ADD_CARDS_SUCCESS"
export const ADD_CARDS_LOADING_START = "ADD_CARDS_LOADING_START"
export const ADD_CARDS_ERROR = "ADD_CARDS_ERROR"

export const DELETE_CARDS_SUCCESS = "DELETE_CARDS_SUCCESS"
export const DELETE_CARDS_LOADING_START = "DELETE_CARDS_LOADING_START"
export const DELETE_CARDS_ERROR = "DELETE_CARDS_ERROR"
export const RESET_CARD_STATE = "RESET_CARD_STATE"

export const UPDATE_CARD_STATUS_SUCCESS = "UPDATE_CARD_STATUS_SUCCESS"
export const UPDATE_CARD_STATUS_LOADING_START = "UPDATE_CARD_STATUS_LOADING_START"
export const UPDATE_CARD_STATUS_ERROR = "UPDATE_CARD_STATUS_ERROR"


// payments
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const GET_PAYMENTS_LOADING_START = "GET_PAYMENTS_LOADING_START"
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR"

export const UPDATE_PAYMENT_STATUS_SUCCESS = "UPDATE_PAYMENT_STATUS_SUCCESS"
export const UPDATE_PAYMENT_STATUS_LOADING_START = "UPDATE_PAYMENT_STATUS_LOADING_START"
export const UPDATE_PAYMENT_STATUS_ERROR = "UPDATE_PAYMENT_STATUS_ERROR"

export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_LOADING_START = "UPDATE_PAYMENT_LOADING_START"
export const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR"

export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS"
export const ADD_PAYMENT_LOADING_START = "ADD_PAYMENT_LOADING_START"
export const ADD_PAYMENT_ERROR = "ADD_PAYMENT_ERROR"

export const DELETE_PAYMENTS_SUCCESS = "DELETE_PAYMENTS_SUCCESS"
export const DELETE_PAYMENTS_LOADING_START = "DELETE_PAYMENTS_LOADING_START"
export const DELETE_PAYMENTS_ERROR = "DELETE_PAYMENTS_ERROR"
export const SET_PAYMENT_FILTERS = "SET_PAYMENT_FILTERS"
export const INIT_PAYMENT_PARAMS = "INIT_PAYMENT_PARAMS"
export const SET_CUR_YEAR = "SET_CUR_YEAR"

export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE"

export const HIDE_ADD_NOT_POPUP = "HIDE_ADD_NOT_POPUP"


// users
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_LOADING_START = "GET_USERS_LOADING_START"
export const GET_USERS_ERROR = "GET_USERS_ERROR"

export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS"
export const ADD_USERS_LOADING_START = "ADD_USERS_LOADING_START"
export const ADD_USERS_ERROR = "ADD_USERS_ERROR"

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_LOADING_START = "DELETE_USER_LOADING_START"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"
export const RESET_USER_STATE = "RESET_USER_STATE"
